.avatar {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 32px;
  width: 32px;
  font-size: 21px;
  line-height: 32px;
}
.avatar.img-thumbnail {
  padding: 4px;
  max-width: none;
}
.avatar > * {
  height: 100%;
  width: 100%;
}
.avatar-16 {
  height: 16px;
  width: 16px;
  font-size: 11px;
  line-height: 16px;
}
.avatar-24 {
  height: 24px;
  width: 24px;
  font-size: 16px;
  line-height: 24px;
}
.avatar-32 {
  height: 32px;
  width: 32px;
  font-size: 21px;
  line-height: 32px;
}
.avatar-48 {
  height: 48px;
  width: 48px;
  font-size: 32px;
  line-height: 48px;
}
.avatar-64 {
  height: 64px;
  width: 64px;
  font-size: 43px;
  line-height: 64px;
}
.avatar-96 {
  height: 96px;
  width: 96px;
  font-size: 64px;
  line-height: 96px;
}
.avatar-128 {
  height: 128px;
  width: 128px;
  font-size: 85px;
  line-height: 128px;
}
@media (min-width: 576px) {
  .avatar-sm-16 {
    height: 16px;
    width: 16px;
    font-size: 11px;
    line-height: 16px;
  }
  .avatar-sm-24 {
    height: 24px;
    width: 24px;
    font-size: 16px;
    line-height: 24px;
  }
  .avatar-sm-32 {
    height: 32px;
    width: 32px;
    font-size: 21px;
    line-height: 32px;
  }
  .avatar-sm-48 {
    height: 48px;
    width: 48px;
    font-size: 32px;
    line-height: 48px;
  }
  .avatar-sm-64 {
    height: 64px;
    width: 64px;
    font-size: 43px;
    line-height: 64px;
  }
  .avatar-sm-96 {
    height: 96px;
    width: 96px;
    font-size: 64px;
    line-height: 96px;
  }
  .avatar-sm-128 {
    height: 128px;
    width: 128px;
    font-size: 85px;
    line-height: 128px;
  }
}
@media (min-width: 768px) {
  .avatar-md-16 {
    height: 16px;
    width: 16px;
    font-size: 11px;
    line-height: 16px;
  }
  .avatar-md-24 {
    height: 24px;
    width: 24px;
    font-size: 16px;
    line-height: 24px;
  }
  .avatar-md-32 {
    height: 32px;
    width: 32px;
    font-size: 21px;
    line-height: 32px;
  }
  .avatar-md-48 {
    height: 48px;
    width: 48px;
    font-size: 32px;
    line-height: 48px;
  }
  .avatar-md-64 {
    height: 64px;
    width: 64px;
    font-size: 43px;
    line-height: 64px;
  }
  .avatar-md-96 {
    height: 96px;
    width: 96px;
    font-size: 64px;
    line-height: 96px;
  }
  .avatar-md-128 {
    height: 128px;
    width: 128px;
    font-size: 85px;
    line-height: 128px;
  }
}
@media (min-width: 992px) {
  .avatar-lg-16 {
    height: 16px;
    width: 16px;
    font-size: 11px;
    line-height: 16px;
  }
  .avatar-lg-24 {
    height: 24px;
    width: 24px;
    font-size: 16px;
    line-height: 24px;
  }
  .avatar-lg-32 {
    height: 32px;
    width: 32px;
    font-size: 21px;
    line-height: 32px;
  }
  .avatar-lg-48 {
    height: 48px;
    width: 48px;
    font-size: 32px;
    line-height: 48px;
  }
  .avatar-lg-64 {
    height: 64px;
    width: 64px;
    font-size: 43px;
    line-height: 64px;
  }
  .avatar-lg-96 {
    height: 96px;
    width: 96px;
    font-size: 64px;
    line-height: 96px;
  }
  .avatar-lg-128 {
    height: 128px;
    width: 128px;
    font-size: 85px;
    line-height: 128px;
  }
}
@media (min-width: 1200px) {
  .avatar-xl-16 {
    height: 16px;
    width: 16px;
    font-size: 11px;
    line-height: 16px;
  }
  .avatar-xl-24 {
    height: 24px;
    width: 24px;
    font-size: 16px;
    line-height: 24px;
  }
  .avatar-xl-32 {
    height: 32px;
    width: 32px;
    font-size: 21px;
    line-height: 32px;
  }
  .avatar-xl-48 {
    height: 48px;
    width: 48px;
    font-size: 32px;
    line-height: 48px;
  }
  .avatar-xl-64 {
    height: 64px;
    width: 64px;
    font-size: 43px;
    line-height: 64px;
  }
  .avatar-xl-96 {
    height: 96px;
    width: 96px;
    font-size: 64px;
    line-height: 96px;
  }
  .avatar-xl-128 {
    height: 128px;
    width: 128px;
    font-size: 85px;
    line-height: 128px;
  }
}
@media (min-width: 1400px) {
  .avatar-xxl-16 {
    height: 16px;
    width: 16px;
    font-size: 11px;
    line-height: 16px;
  }
  .avatar-xxl-24 {
    height: 24px;
    width: 24px;
    font-size: 16px;
    line-height: 24px;
  }
  .avatar-xxl-32 {
    height: 32px;
    width: 32px;
    font-size: 21px;
    line-height: 32px;
  }
  .avatar-xxl-48 {
    height: 48px;
    width: 48px;
    font-size: 32px;
    line-height: 48px;
  }
  .avatar-xxl-64 {
    height: 64px;
    width: 64px;
    font-size: 43px;
    line-height: 64px;
  }
  .avatar-xxl-96 {
    height: 96px;
    width: 96px;
    font-size: 64px;
    line-height: 96px;
  }
  .avatar-xxl-128 {
    height: 128px;
    width: 128px;
    font-size: 85px;
    line-height: 128px;
  }
}
