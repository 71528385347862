:root {
  --white-color: #fff;
  --primary-color: #058f38;
  --placeholder-color: #a4a4a4;
  --border-color: #eaeaea;

  --gray-1: #363636;

  --app-primary-color: #0d2553;
}
