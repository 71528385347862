/* Body text */
.body-12 {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 300;
}
.body-15 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 400;
}
.body-17 {
  font-size: 1.41rem;
  line-height: 1.5rem;
  font-weight: 500;
}
.body-18 {
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-weight: 500;
}
.body-24 {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 500;
}
.body-25 {
  font-size: 2.08rem;
  line-height: 2.5rem;
  font-weight: 500;
}
.body-30 {
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: 600;
}

/* Headings */
h1 {
  font-size: 4.66rem;
  line-height: 5.58rem;
  font-weight: 700;
}

/* Font weights */
.f-lighter {
  font-weight: 300;
}

.f-light {
  font-weight: 400;
}

.f-normal {
  font-weight: 500;
}

.f-bold {
  font-weight: 600;
}

.f-bolder {
  font-weight: 700;
}

/* Text colors */

.text-color-primary {
  color: var(--primary-color);
}

.text-color-gray-1 {
  color: var(--gray-1);
}
